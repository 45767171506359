.App {
}

header {
  padding: 3em;
}

header a {
  text-decoration: none;
  color: #00f;
}

h1 {
  margin: 0;
}

body {
  text-align: center;
}

.page {
  padding: 0;
  background-color: #fff;
  display: inline-block;
  text-align: left;
}

.recipe {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.text {
  background-color: #fff;
  padding: 3em;
  position: relative;
  z-index: 1000;
}

.title {
  margin: 0;
}

.date {
  font-size: 10pt;
  margin-bottom: 0.25em;
}

.cook-time {
  font-size: 10pt;
  margin: 0;
}

@media screen and (orientation: landscape) {
  .yt-container {
    position: sticky;
    top: 3em;
    width: calc((100vh - 6em) * 9 / 16) ;
    height: calc(100vh - 6em);
  }

  .text {
    max-width: 30em;
  }
}

.yt-container iframe,
.yt-container object,
.yt-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.steps {
  padding-left: 1.1em;
}

.step {
  padding-left: 0.5em;
  margin-top: 1em;
}

.step::marker {
  font-weight: bold;
}

.step-duration {
  margin-left: 0.5em;
  font-size: 10pt;
}

@media screen and (orientation: portrait) {
  .yt-container {
    position: sticky;
    top: -10vh;
    width: calc(100vw);
    height: calc((100vw) * 16 / 9);
  }

  .recipe {
    flex-direction: column;
    padding: 0;
    align-items: stretch;
  }

  .text {
    padding: 2em;
  }
}
